li,
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
header {
  font-family: Pretendard;
  font-size: 16px;
  background: #FFF;
}
.navMenu a,
.submenu a,
a:active,
a:focus,
a:hover {
  color: #222222 !important;
}
.header-navigation {
  background-color: #fff;
  height: 120px;
  /* position: relative; */
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 15px;
}
.navtextUl {
  text-align: right;
}
.header-text {
  color: #828282;
}
.gnb {
  position: relative;
  width: 100%;
  margin: 0 auto;
}
.navUl {
  width: 100%;
  padding: 0;
  margin: 0 auto;
  text-align: left;
}

.navUl > li {
  display: inline-block;
  height: 120px;
  padding-top: 10px;
}
.logo-link {
  width: 100%;
  max-width: 378px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}
.logo-img {
  width: 100%;
  max-width: 378px;
  height: auto;
  aspect-ratio: 63 / 8;
}
.textNav a {
  color: #525252;
}
.main-menu-wrap {
  text-align: center;
}
.navMenu {
  width: 100px;
  background: #FFF;
}
.navMenu > a {
  line-height: 120px;
  font-weight: 400;
  font-size: 18px;
  display: block;
  color: #444 !important;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.02em;
}
.navMenu a:hover {
  color: var(--point-color, #e94e1b) !important;
}
/*
.navMenu a:hover::after {
  content: '';
  display: block;
  width: 100%;
  height: 3px;
  background-color: var(--point-color, #E94E1B);
  position: absolute;
  bottom: 0px;
}
*/
.dropdownWrap {
  position: absolute;
  width: 100vw;
  top: 120px;
  left: 0;
  right: 0;
  z-index: 99;
  min-height: 25vh;
  height: auto;
  max-height: fit-content;
  border-top: 1px solid #f2f2f2;
  margin: 0 auto;
}
.dropdownWrap::before {
  content: "";
  display: block;
  position: absolute;
  width: 100vw;
  height: 25vh;
  background-color: #fff;
}
.submenuUl {
  display: none;
  position: absolute;
  top: 120px;
  left: 0;
  right: 0;
  z-index: 100;
  height: fit-content;
  min-height: 25vh;
  display: inline-block;
  border-top: 1px solid #f2f2f2;
  vertical-align: top;
  margin: 0 auto;
  background-color: #fff;
  padding: 15px 0;
}
.active {
  display: block;
}
.submenuDiv {
  position: absolute;
  z-index: 100;
  width: 100%;
  margin-top: -1px;
}
.navMenu:first-child .submenuDiv::before {
  content: "";
  display: block;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
  width: 1000vw;
  transform: translateX(-50vw);
  border-top: 1px solid #a4a4a4;
}
.submenuDiv a {
  font-size: 1em;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  opacity: 0.5;
}
.submenuDiv a:hover {
  opacity: 1;
}
.submenuDiv > ul {
  width: fit-content;
  text-align: center;
  position: relative;
  height:120px;
}
.submenuDiv li {
  line-height:60px;
}
.submenuUl li {
  line-height: 30px;
  height: 40px;
  overflow: hidden;
  color: #222222;
  font-size: 0.9rem;
  text-align: left;
  display: inline-block;
  width: 150%;
  margin: 0 auto;
}
.submenuUl li a {
  text-align: center;
  display: inline-block;
}
.submenuUl li a:hover {
  font-weight: 400 !important;
  color: #000000;
}
.subdep a {
  font-size: 0.9rem !important;
  color: #828282 !important;
  padding-left: 4px !important;
}
.ant-drawer-body .submenuDiv {
  margin-top: 50px;
  padding: 2em;
  font-size: 16px;
}
.ant-drawer-body .submenuDiv a {
  line-height: 80px;
}
.ant-drawer-body .navMenu a::after {
  content: "|";
  display: block;
  float: right;
  font-weight: 400;
  opacity: 0.3;
  padding-right: 1em;
}
.submenuUl {
  transform: none;
}
.gnb {
  max-width: 1440px;
}
.navUl > li.logo:hover::after {
  display: none;
}
.navUl > li:hover::after {
  content: "";
  display: block;
  width: 120px;
  height: 3px;
  background: #222;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  min-width: 100%;
}
.dropdown {
  width: 1470px;
  max-width: 100vw;
  height: 100%;
  position: relative;
  margin: 0 auto;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}
.board-main-wrap .ant-layout .ant-layout-header {
  position: relative;
}
.gnb .anticon-user {
  display: none;
}
.submenuDiv ul {
  margin: 0 auto;
}

@media only screen and (max-width: 1400px) {
  .gnb {
    font-size: 13px;
  }
  .textNav a {
    font-size: 13px;
  }
  .navtextUl > .ant-row {
    align-items: center;
  }
}
@media only screen and (max-width: 1046px) {
  .navUl .logo {
    margin: 0 1em 0 0;
  }
  .ant-layout-sider,
  .navUl > li:hover::after {
    display: none;
  }
  .submenuUl {
    min-height: 40vh;
  }
  .dropdown {
    background-image: none;
  }
  .sign-text,
  .login .textNav a {
    font-size: 0;
  }
  .login .textNav a {
    display: inline-block;
    padding: 0 20px;
  }
  .login .textNav:first-child {
    display: none;
  }
  .navtextUl span.anticon {
    display: inline-block;
    background: #ffd560;
    border-radius: 6px;
    padding: 2px;
    margin: 0 4px;
    font-size: 13px;
  }
  .header-navigation,
  .navMenu > a,
  .navtextUl {
    height: 60px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 768px) {
  .ant-layout-header {
    margin-top: 120px;
  }
  .navMenu,
  .navUl > li:hover::after,
  .submenuUl {
    width: 86px;
  }
  .submenuUl {
    padding: 0.5em;
    min-height: 30vh;
  }
  .navtextUl {
    right: -4px;
  }
  .snsUl {
    bottom: -30px;
  }
}

@media only screen and (max-width: 418px) {
  .navUl > li:hover::after {
    display: none;
  }
  .login .textNav a {
    display: inline-block;
    padding: 0;
  }
}

.ant-drawer-open {
  z-index: 100000;
}
#Mobile-nav {
  font-size: 1em;
  float:right;
}
.ant-drawer {
  height: 100vh !important;
}

.ant-drawer-content-wrapper {
  width: 100vw !important;
  box-shadow: none !important;
  border: none;
}
.ant-drawer .ant-drawer-body {
  padding: 0;
}
.ant-drawer .ant-tabs {
  flex-direction: row;
  width: 100vh;
  border-top: 1px solid #dbdbdb;
}
.ant-drawer .ant-tabs-nav-list {
  flex-direction: column;
}
.ant-drawer .ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  width: fit-content;
  float: left;
  display: block;
  margin: 0;
}
.ant-drawer .ant-tabs-content-holder {
  float: left;
  width: 50%;
  display: block;
  border-top: 1px solid #f0f0f0;
  padding: 1em 2em;
}
.ant-drawer .ant-tabs-tab {
  line-height: 4;
  width: 150px;
  background: #ffd560;
}
.ant-drawer .ant-tabs-nav-list .ant-tabs-tab:nth-child(5) {
  /* padding-bottom: 10em; */
  position: relative;
}
.ant-drawer .subListUl-m {
  list-style: none;
  padding: 0;
}
.subListUl-m li {
  line-height: 3;
}

.subListUl-m li::before {
  content: "-";
  margin-right: 8px;
}
.subListUl-m li .anticon {
  margin-left: 6px;
  color: #bdbdbd;
}
.subListUl-m ul {
  padding: 0 1em;
}
.ant-drawer .subListUl-m ul li,
.subListUl-m ul li {
  font-weight: normal;
}
.ant-tabs-nav-list .ant-tabs-tab:nth-child(5)::after {
  content: "";
  display: block;
  background: #ffd560;
  height: 500px;
  right: -1px;
  left: 0;
  position: absolute;
  top: calc(100% + 1px);
}
.ant-drawer .ant-tabs,
.nav-wrapper {
  height: calc(100vh - 402px);
  min-height: 500px;
}
.ant-tabs-nav-list,
.ant-tabs-nav-wrap {
  height: 100%;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  border-color: #f0f0f0;
  padding: 0.5em 2em;
}
/* open btn */
.movile-nav-openBtn.ant-btn {
  height: 50px;
  width: 50px;
  border: 0;
}
/* close btn */
.ant-drawer .ant-drawer-mask {
  background-color: #fff;
  right: 14px;
  left: auto;
  width: 50px;
  height: 60px !important;
  z-index: 100;
}

.ant-drawer .ant-drawer-mask::before {
  content: "";
  width: 50px;
  height: 56px;
  display: block;
  position: absolute;
  background: url("./icon-close.svg") no-repeat center;
}
/* antd color custom */
.ant-drawer .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000000;
}
.ant-drawer .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active {
  background: #fff !important;
  color: #fff;
}
.ant-drawer .ant-tabs-tab,
.ant-drawer .ant-tabs-tab:hover {
  background: #ffd560 !important;
}
/* btn color custom */
header .ant-btn:active,
header .ant-btn:focus,
header .ant-btn:hover {
  color: #000000 !important;
  border: none;
  padding: 1em;
}
/* mobile */
@media only screen and (max-width: 786px) {
  .main-menu-wrap {
    display: none;
  }
  .logo-img {
    height: auto;
  }
  /* mobile menu  */
  body {
    padding-top: 60px;
  }
  .header-navigation {
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index:101;
  }
  .dropdownWrap,
  .navUl > li.navMenu {
    display: none;
  }
  .gnb .onlyMobile,
  .navtextUl .textNav:nth-child(3) {
    display: inline-block !important;
  }
  .navtextUl {
    padding: 0;
  }
  .logo a {
    line-height: 1;
    max-width: 300px;
  }
  .navUl {
    height: 50px;
  }
  .navUl > li {
    height: 50px;
    padding-top: 10px;
    padding-left: 10px;
  }
  .logo-m img {
    display: block;
    height: 30px;
  }
}
@media only screen and (max-width: 576px) {
  .ant-drawer-body .submenuDiv {
    font-size: 14px;
    padding: 0 0 0 1em;
  }
}
