footer .logo-img {
    max-width: 150px;
    aspect-ratio: 13 / 4;
}
.ant-drawer footer {
    background: #fff!important;
    border-top: 1px solid rgb(189, 189, 189);
}
.ant-drawer footer .copyright {
    background: transparent!important;
    border-top: 1px solid rgb(189, 189, 189);
}
.ant-drawer footer span {
    color: #333!important;
}
footer {
    width: 100%;
    background: #E0E0E0;
    padding: 1em 40px;
}
footer .container {
    max-width: 1440px;
    margin: 0 auto;
    min-height: 200px;
}
footer .container .contents-wrap {
    align-items: center;
}
footer .container .copyright {
    font-size: 0.85em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0;
    text-align: left;
    opacity: 0.8;
}
footer .container .copyright p:not(:last-child) {
    margin-bottom: 1em;
}
footer .container .link-box {
    float: right;
    height: 48px;
    width: 200px;
    border-radius: 2px;
    border: 1px solid #828282;
}
footer .container .link-box.ant-dropdown-open .drop-up {
    transform: rotate(180deg);
    transition: 0.4s;
}
footer .container .ant-dropdown-link {
    display: block;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 46px;
}
footer .container .ant-dropdown-link span {
    padding: 0 1em;
}
footer .container .ant-dropdown-link .drop-up {
    margin-left: 1em;
    width: 10px;
    height: 5px;
}
div .site-menu {
    padding: 0;
}
div .site-menu li {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;

    color: var(--text-color, #333);
    line-height: 38px;

}
div .site-menu li:not(:last-child) {
    border-bottom: 1px solid #E0E0E0;
}
p {
    margin-bottom: 0;
}
footer {
    font-size: 16px;
    position: relative;
}
footer .ant-dropdown-menu-title-content > a {
    color: #525252;
}
footer .logo-link {
    font-size: 0;
}
footer .terms {
    /* position: absolute; */
    font-size: .8em;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0em;

}
footer .terms .container {
    min-height: auto;
}
footer .terms-link * {
    color: var(--point-color, #e94e1b);
}
footer .terms div div:not(:last-child):after {
    content: '|';
    padding: 0 12px;
}
@media only screen and (max-width: 1046px) {
    }
@media only screen and (max-width: 910px) {
    footer .container .link-box {
        width: 164px;
    }
}
@media only screen and (max-width: 768px) {
    footer {
        background: #e0e0e020;
        margin-top: 150px;
    }
    footer .container .link-box {
        float: none;
    }
    footer .logo-img {
        display: none;
    }
    footer .terms  {
        left: 20px;
        padding-left: 0;
    }
}
@media only screen and (max-width: 599px) {
    footer {
        padding: 1em 20px;
    }
}

@media only screen and (max-width: 418px) {
    footer .termsUl li {
        margin: 0 2px!important;
    }
    footer .terms div div:nth-child(2) {
        display: none;
    }
}
