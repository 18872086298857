/* Layout */
.boardLayout {
  width: 100vw;
  height: 100%;
  min-height: calc(100vh - 350px);
}

/* boardContent */
.boardContent {
  width: 100%;
  margin: 0 auto;
  padding: 50px 0;
}

.board-content-wrap {
  padding-bottom: 50px;
  padding-left: 50px;
}

/* Board Header*/
.ant-layout-header {
  background-color: #ffffff;
  height: 300px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}
.bw-titleWrap {
  height: 300px;
  background-image: url("assets/image/background-img.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.bw-title {
  color: #ffffff;
  font-weight: 700; /*dorothy*/
  font-size: 2rem;
  vertical-align: middle;
}

/* Board Top Button */
.board-top-control-bt {
  width: 100%;
  margin: 0;
  /* margin-top: 25px; */
  /* beige box에 맞출 때  */
  margin-top: 38px;
  /* height: 122px; */
}

/*  sider */
.ant-layout-sider {
  background-color: #ffffff;
}
.siderWrap {
  height: calc(100% - 200px);
  width: 100%;
  background-color: #ffffff;
  margin: 0 auto;
  padding: 0;
  top: 200px;
  margin-top: 18px;
  padding-top: 24px;
}

.siderWrap a {
  cursor: pointer;
}

.siderWrap li a:hover {
  color: #222222;
}

.siderWrap ul {
  padding: 0;
  margin: 0 auto;
}
.siderWrap li {
  color: #222222;
  font-size: 0.9rem;
}

.first-side-menu {
  text-align: left;
  width: 100%;
}

.first-side-title {
  text-align: center;
  display: inline-block;
  width: 200px;
  background-color: #ecd7be;
  height: 120px;
}

.first-side-title span {
  font-weight: bold;
  font-size: 18px;
  line-height: 120px;
}

.second-side-menu {
  text-align: left;
  width: 100%;
}

.second-wrap-div:first-child {
  margin-top: 20px;
}

.second-side-title {
  text-align: left;
  display: inline-block;
  width: 100%;
  margin: 0 auto 10px auto;
  border-bottom: 1px solid #333333;
  padding-bottom: 7.5px;
}

.second-side-title a {
  font-weight: bold;
}

.third-side-title {
  text-align: left;
  display: inline-block;
  width: 100%;
  line-height: 30px;
}

.third-side-title:last-child {
  margin-bottom: 20px;
}

.third-side-title a {
  font-size: 0.9rem !important;
  color: #828282;
  font-weight: 400;
}

/* Form */
.formInput {
  width: 100%;
}

/* Editor */
.quill-blur {
  display: none;
}

/* List */
.ant-table-thead > tr > th {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  background: transparent;
  border-bottom: 1px solid #ffd560;
  /* -webkit-transition: background 0.3s ease; */
  /* transition: background 0.3s ease; */
}

/* board-title-link */
.board-title-link {
  color: #333333;
  display: inline-block;
  padding: 3px;
}

.board-main-wrap .ant-table-thead .ant-table-cell:nth-child(2) {
  text-align: left;
}

/* Board Basic */

.ql-editor {
  max-height: 100%;
  height: 100%;
}

.quill {
  width: 100%;
  min-height: 200px;
  padding: 25px 0;
}

.quill img {
  max-width: 100%;
}

.quill-blur {
  display: none;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
}
.ant-select {
  line-height: 40px;
  margin-right: -1px;
}

/* 수정금지 */
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  line-height: 30px;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus,
.ant-input:focus,
.ant-input-focused,
.ant-input:hover,
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}
.ant-upload-list {
  float: left;
}
.ant-form-item-label > label {
  font-size: 16px;
  font-weight: 600;
}

.writeWrap {
  padding: 70px 0;
}

.writeForm {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
}

.ant-form {
  width: 100%;
}

.ant-input,
.ant-picker {
  height: 40px;
  float: left;
}

/* 이미지 박스 */
.ant-upload-list-picture-card-container,
.ant-upload.ant-upload-select-picture-card {
  width: 235px;
  height: 235px;
  display: inline-block;
  margin: 0;
  vertical-align: top;
}

.mainInfo {
  padding: 40px;
  /* #FFD560 */
  border: 1px solid rgb(197, 197, 197);
  border-radius: 12px;
  margin-bottom: 40px;
}

.writeTitle {
  width: 100%;
  height: 110px;
  font-size: 2rem;
}

.shortInput {
  /* width:220px; */
  width: 100%;
}

.mediumInput {
  width: 340px;
  max-width: 100%;
}
.thumbCol .ant-form-item {
  margin-bottom: 18px;
}

.cateCol {
  padding-left: 2.5%;
}
.dateCol {
  padding-left: 3%;
}

/* List View */
.ant-table {
  font-size: 16px;
}
.ant-table table {
  margin: 30px auto;
}

.ant-table-thead .ant-table-cell {
  background: transparent;
  padding-left: 20px;
  text-align: center;
}

.ant-table-thead,
.ant-table-thead > tr > th {
  border-bottom: 2px solid #f2f2f2;
  border-top: 2px solid #f2f2f2;
  border-left: none;
  border-right: none;
  line-height: 1;
  font-weight: bold;
}
.ant-table-tbody {
  margin-top: 20px;
}
.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  width: 0px;
}
.grayTxt {
  color: #828282;
}

.ant-row {
  width: 100%;
}

/* detail View */
.detailMainInfoWrap {
  width: 100%;
  margin: 18px auto 0;
  position: relative;
  padding: 24px 0 0;
}
.detailFooterWrap {
  width: 100%;
  padding: 0 20px;
  position: relative;
  max-width: 1200px;
  margin: 0 20px;
}
.detailFooterWrap .ant-list-item-action {
  margin-left: 0;
}
.ant-btn.editBt {
  color: cornflowerblue;
  font-weight: bold;
}
main h1.titleText {
  text-align: left;
  padding: 15px 0;
  margin-bottom: 0.8em;
}
.secondaryText {
  position: absolute;
  right: 45px;
  bottom: 28px;
  display: inline-block;
}
.ant-divider.titleDivider {
  border-top: 1px solid #e0e0e0;
}

.ant-divider-horizontal {
  margin-top: 0;
}
.detailIconWrap {
  position: absolute;
  right: 35px;
  top: 5px;
  display: inline-block;
}

.prevnextList a {
  color: #666666;
}

.inputBxStyle {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: relative;
  display: inline-block;
  width: 220px;
  height: 40px;
  min-width: 0;
  padding: 4px 11px;
  line-height: 1.8;
  text-align: center;
}

.inputBxStyle span {
  vertical-align: middle;
  color: rgba(0, 0, 0, 0.3);
  font-size: 16px;
  list-style: none;
}

.dateCol .ant-input,
.dateCol .ant-picker {
  width: 100%;
}

/* contest best detail */
.detail-program-MainInfoWrap {
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  text-align: left;
}
.detail-program-MainInfoWrap button {
  padding: 0;
}
.programDetailInfoWrap {
  width: 100%;
  margin: 35px 40px;
  border: 1px solid #ffd560;
  max-width: 1200px;
  overflow: hidden;
}
.programDetailInfoWrap + div {
  padding: 0 40px 40px !important;
  max-width: 1200px !important;
}
.programDetailInfoWrap .ant-space-item {
  display: table;
}
.programDetailInfoWrap .ant-image {
  display: table-cell;
  vertical-align: middle;
}
.info-first-col {
  width: 100%;
  max-width: 320px;
  max-height: 320px;
  padding-top: 2px;
  overflow: hidden;
}

.info-second-col {
  text-align: left;
  padding-left: 7%;
  padding-top: 5px;
}

.info-second-col h3,
.info-second-col ul li {
  text-align: left;
}

.info-second-col h1.ant-typography,
.info-second-col .ant-typography h1 {
  margin-bottom: 0.4em;
}

.userLimitTxt {
  color: #828282;
  font-size: 0.8rem;
  height: 18px;
  line-height: 18px;
  display: inline-block;
  height: 100%;
  vertical-align: unset;
}
.userLimitTag {
  color: white;
  display: inline-block;
  height: 100%;
  background-color: #ffd560;
  font-size: 0.8rem;
  padding: 0 7px;
  border-radius: 12px;
  vertical-align: unset;
}
.listCol {
  padding-top: 20px;
}

.listCol,
.listCol ul {
  padding: 16px 16px 0 0;
  height: 100%;
  margin: 0;
}
.listCol li {
  text-align: left;
  height: 50px;
  font-size: 14px;
  padding-top: 16px;
  border-bottom: 1px solid #e3e3e3;
  vertical-align: bottom;
  width: 100%;
  overflow: hidden;
  font-weight: 600;
}
.listCol li span {
  display: inline-block;
  vertical-align: bottom;
}
.info-second-col .listTitle {
  width: 30%;
  min-width: 60px; /*dorothy*/
  font-weight: 500; /*dorothy*/
}

.qulii-body-row {
  width: 100%;
  border-top: 1px solid #ffd560;
  margin: 0 40px 35px;
  max-width: 1000px;
  overflow: hidden;
}

.bottomInquiry {
  width: 100%;
  max-width: 1000px;
  height: 60px;
  margin: 30px auto 0;
  text-align: left;
  border-bottom: 1px solid #ffd560;
  position: relative;
}
.bottomInquiry span,
.bottomInquiry h4 {
  vertical-align: bottom;
}

.bottomInquiry button {
  border-color: #ffd560;
  width: 170px;
  height: 35px;
  position: absolute;
  right: 0;
  bottom: 20px;
}

.bottomInquiry button:hover,
.bottomInquiry button:active,
.bottomInquiry button:focus {
  background-color: #ffd560;
  border-color: #ffd560;
  color: #000000;
}

.btnApply {
  background-color: #ffd560;
  border-color: #ffd560;
  width: 170px;
  height: 35px;
  color: #000000;
}

.btnApply:hover,
.btnApply:active,
.btnApply:focus {
  background-color: #ffd560;
  border-color: #ffd560;
  color: #000000;
}
/* 강좌신청 모달팝업 */
.applyModal {
  width: 45%;
  max-width: 600px;
  min-width: 300px;
}
.applyModal .ant-modal-content {
  padding: 20px;
  text-align: center;
}

.applyModalBtn {
  width: 41%; /*210701 Sarc*/
  max-width: 170px;
  height: 35px;
  border-color: #ffd560;
  font-weight: 400;
  color: #000000;
}

.applyModalBtn:hover,
.applyModalBtn:active,
.applyModalBtn:focus {
  border-color: #ffd560;
  color: #000000;
}

.reloadSearchBtn {
  /* 회원가입전 유저조회 처리용 임시 스타일 적용 by Sarc 210630 */
  position: absolute;
  right: 0;
  width: 35px;
  height: 35px;
  border-color: #ffd560;
  font-weight: 400;
  color: #000000;
}
.reloadSearchBtn:hover,
.reloadSearchBtn:active,
.reloadSearchBtn:focus {
  border-color: #ffd560;
  color: #000000;
}

.ant-modal-close {
  display: none;
}

.ant-result-error {
  padding: 20px 0;
}
.ant-result-title {
  font-weight: 600;
}
.ant-result-icon {
  margin-bottom: 40px;
}
.ant-result-subtitle {
  font-size: 14px;
  font-weight: 600;
  color: #828282;
  line-height: 20px;
}

.ant-result-extra {
  margin-top: 70px;
}
.ql-tooltip,
.ql-hidden {
  display: none !important;
}

.detail-title {
  position: fixed;
  top: 120px;
  width: 100vw;
}

/* quill editor에서 삽입될 비디오 사이즈 조절. client쪽과도 동일하게 맞춰야 함. by Sarc */
.ql-editor .ql-video {
  width: 720px;
  height: 405px;
}
/* asha */
.ant-table-pagination.ant-pagination {
  display: inline-block;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: none;
}
.ant-pagination .ant-pagination-item-active,
.ant-pagination .ant-pagination-item-active:focus,
.ant-pagination .ant-pagination-item:hover {
  border-color: transparent;
  border: none;
  opacity: 1;
}
.ant-pagination-item {
  border: none;
}
/* antd custom- pagination - page number - dorothy.ver copy by asha */
.ant-pagination {
  margin: 3em 0;
  width: 100%;
  text-align: center;
}
.ant-pagination.mini .ant-pagination-item {
  font-size: 18px;
  font-weight: 400;
  opacity: 0.5;
  margin: 0 2px;
  padding: 4px;
  box-sizing: content-box;
}
.ant-pagination.mini .ant-pagination-item-active,
.ant-pagination.mini .ant-pagination-item-active:focus,
.ant-pagination.mini .ant-pagination-item:hover {
  border-color: #ddd;
  opacity: 1;
}
.ant-pagination.mini .ant-pagination-item-active a, .ant-pagination-item:focus a, .ant-pagination-item:hover a,/*page number color*/
.ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
  color: #f2994a;
}
.ant-pagination.mini .ant-pagination-prev,
.ant-pagination.mini .ant-pagination-next {
  margin: 0 4px;
}

/* 업로드쪽용 스타일 임시 by Sarc */
.ant-upload-list-text-container {
  cursor: pointer;
}
.ant-upload-list-text-container .ant-upload-list-item-name {
  text-align: left;
}
.ant-upload-list-text-container span {
  color: #0645ad;
}

/* Asha */

.textAreaView.ant-input[disabled] {
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  padding: 25px;
  border: none;
  overflow-y: auto;
  cursor: auto; /*비활성 상태일때 빨간사선원 마우스 커서 뜨는거 처리 by Sarc 210507*/
}
::-webkit-scrollbar {
  width: 3px;
  height: 4px;
  display: block;
  background-color: #ffffff;
}
::-webkit-scrollbar-thumb {
  background: rgb(218, 218, 218);
}
/* Dorothy */
* {
  scrollbar-width: thin; /* firefox scrollbar */
  -ms-overflow-style: none; /* IE and Edge scrollbar */
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* IE and Edge style */
  
}
@media screen and (min-width: 0) and (max-width: 830px) {
  .cateCol {
    padding-left: 7%;
  }
  .edit-focus .quill {
    position: absolute;
    top: 0;
    height: 226px;
    overflow: hidden;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 1;
  }
  .edit-focus .quill-blur {
    display: block;
    position: absolute;
    top: 226px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 1;
  }
  .edit-focus .ql-editor {
    height: 160px;
    border-bottom: 1px solid #ccc;
  }
  .edit-focus .navbar {
    display: none;
  }
  .edit-focus .main-panel {
    padding: 0 !important;
  }
  .edit-focus .main-panel .main-content {
    min-height: unset !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .info-first-col {
    margin: 1em auto;
  }
}

/* dorothy */
main.board-content-wrap {
  padding-left: 0;
}
main .detailHeaderWrap,
main .detailMainInfoWrap {
  padding-left: 20px;
  padding-right: 20px;
}
main .ql-editor {
  padding: 12px 40px;
}
.detailHeaderWrap + .ant-row + .ant-row-middle {
  padding: 0!important;
}
main .ql-toolbar + .ql-container {
  padding: 15px;
}
.detailMainInfoWrap .ant-btn-text {
  padding-left: 0;
  float: left;
}
main .board-list-header-wrap {
  padding: 25px 0 0;
  margin-top: 18px;
}
main .list-text-bt {
  font-weight: 400;
}
main .qna-detail {
  padding: 0 40px 20px;
}
.statusTag {
  text-align: center;
}
.detailHeaderWrap,
.detailMainInfoWrap {
  max-width: 1200px;
}
.detailMainInfoWrap + .ant-row + .ant-row {
  flex-direction: column;
}
/* .detailMainInfoWrap + .ant-row +.ant-row, */
.detailMainInfoWrap + .ant-row + .ant-row + .ant-row {
  max-width: 1200px;
  margin: 0 20px !important;
}
.detailHeaderWrap + .ant-row > .ant-col-24,
.detailMainInfoWrap + .ant-row > .ant-col-24 {
  max-width: 1200px;
  padding: 0 0 0 40px !important;
}
.detail-edit-col h2 {
  text-align: center;
}

.WithSkeleton .ant-col-24,
.WithSkeleton .detailMainInfoWrap + .ant-row {
  padding: 0 !important;
}
.WithSkeleton .secondaryText {
  right: 0;
}
main .qna-detail .detailHeaderWrap {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
main .ant-comment-content-detail {
  padding: 1em;
}
/* 이전글, 다음글 TEXT 길면 생략 */
.detailFooterWrap .ant-list-item-action {
  width: 100%;
}
.detailFooterWrap .ant-list-item-action > li:first-child {
  width: 75px;
}
.detailFooterWrap .ant-list-item-action > li {
  width: calc(100% - 75px);
  text-align: left;
}
.detailFooterWrap .ant-list-item-action > li:last-child a {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
}
@media screen and (max-width: 1200px) {
  .cateCol {
    width: 100%;
    padding-left: 5%;
  }
  .cateCol .ant-input,
  .cateCol .ant-select {
    width: 100% !important;
  }

  .dateCol {
    width: 100%;
    padding-left: 0;
  }
  .dateCol .ant-input,
  .dateCol .ant-picker {
    width: 100%;
  }
}

@media screen and (min-width: 1300px) {
  .dateCol {
    padding-left: 3%;
  }
}
@media screen and (max-width: 1440px) {
  .ant-layout-sider {
    margin-left: 25px;
  }
  main .detailHeaderWrap,
  main .detailMainInfoWrap,
  .detailHeaderWrap + .ant-row > .ant-col-24,
  .detailMainInfoWrap + .ant-row > .ant-col-24 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}
@media screen and (max-width: 1046px) {
  main.board-content-wrap .board-top-control-bt .ant-row-end .ant-col-4 {
    min-width: 70px;
    margin-right: 4px;
  }
}
@media screen and (max-width: 768px) {
  h1.ant-typography,
  .ant-typography h1 {
    font-size: 2em;
  }
  .board-main-wrap .quill img {
    display: block;
    /* max-width: 80vw; */
    margin: 0 auto;
  }
  /* basic board list */
  .List {
    margin: 0 !important;
    padding: 0 !important;
  }
  .List .ant-table-tbody > tr > td,
  .List .ant-table-thead > tr > th {
    padding: 1em 0.5em;
  }
  .detailHeaderWrap .ant-col-offset-18,
  .detailMainInfoWrap .ant-col-offset-18 {
    margin-left: calc(75% - 70px);
  }
  .detailHeaderWrap .detail-edit-col,
  .detailHeaderWrap .detail-delete-col,
  .detailMainInfoWrap .detail-edit-col,
  .detailMainInfoWrap .detail-delete-col {
    min-width: 70px;
  }
  /* 멘토링QNA DETAILE */
  .qna-detail .detailHeaderWrap + .border-grid {
    border-bottom: none;
    margin-top: 50px;
  }
  .qna-detail .border-grid-row {
    border-top: none;
    border-bottom: none;
  }
  .qna-detail .ant-col-sm-18,
  .qna-detail .ant-col-sm-6 {
    border-bottom: 1px solid rgb(224, 224, 224);
    border-left: none !important;
  }
  .qna-list table {
    min-width: 800px;
  }
  .qna-list .ant-table {
    max-width: 100vw;
    overflow-x: scroll;
  }
  .WithSkeleton .ant-col-24,
  .WithSkeleton .detailMainInfoWrap + .ant-row {
    padding: 0 !important;
  }
}
@media screen and (max-width: 599px) {
  .board-main-wrap .ant-table {
    font-size: 14px;
  }
  .listData {
    width: 100%;
  }
  .listCol li {
    height: 62px;
  }
  .listCol,
  .listCol ul {
    padding: 24px 16px 16px 0;
  }
  /* inquriy btn*/
  .bottomInquiry {
    height: 112px;
  }
  .bottomInquiry button {
    /*dorothy*/
    left: 0;
    width: 100%;
  }
  /* basic board - notice */
  .List,
  .List .ant-table-thead {
    padding: 0 4px !important;
  }
  .List .ant-table-container .writerTxt {
    text-indent: -9999px;
  }
  .List .ant-table-tbody > tr > td,
  .List .ant-table-thead > tr > th {
    padding: 1em 0;
  }
  .bottomInquiry button {
    position: relative;
    bottom: -20px;
    min-width: fit-content;
    display: block;
  }
  .edit-content-wrap > .ant-form-item {
    flex-direction: row;
  }
  .edit-content-wrap > .ant-form-item .ant-form-item-label {
    width: 100%;
  }
  main .detailHeaderWrap {
    justify-content: space-between;
  }
  .detailHeaderWrap .ant-col-offset-18,
  .detailMainInfoWrap .ant-col-offset-18 {
    margin-left: calc(75% - 90px);
  }
  main .detailHeaderWrap,
  main .detailMainInfoWrap,
  .detailHeaderWrap + .ant-row > .ant-col-24,
  .detailMainInfoWrap + .ant-row > .ant-col-24,
  main .qna-detail,
  main .qna-detail .border-grid-content {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .detailFooterWrap {
    margin: 0;
  }
  .detailMainInfoWrap + .ant-row > .ant-skeleton-active,
  main .ant-comment-content-detail,
  .ant-comment-inner,
  .ant-comment-content {
    padding: 0 !important;
  }
  main  #replyform .ant-col > div {
    min-height: auto;
  }
  .ant-form .ant-form-item {
    margin-bottom: 20px;
  }
  .ant-form .ant-form-item:last-child {
    margin-bottom: 0;
  }
  main .comment-box,
  main .ql-editor {
    padding: 20px;
  }
}

.ant-btn-text:hover,
.ant-btn-text:focus,
.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  background: transparent;
}
.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: transparent;
}
.ant-table-tbody > tr.ant-table-row:hover {
  background: #fafafa;
}
/* add by Sarc - 정확한 사유는 모르지만, ie에서는 하단 클래스들에 대해 width가 비정상적으로 넓게 책정되어 max-width로 제한. ie10+에서만 적용된다는 css 적용 */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles goes underneath */
  .ant-space,
  .ant-space-item {
    max-width: 100%;
  }
}

/* 회원가입전 유저조회 처리용 임시 스타일 적용 by Sarc 210630 */
.userExistCheck .ant-modal {
  top: 33vh;
}
#phoneResult {
  margin-left: 82px;
  color: red;
}
@media screen and (max-width: 575px) {
  #phoneResult {
    position: absolute;
    top: 5px;
    margin-left: 65px;
  }
}
/*  main */
.poster .slick-dots li button {
  text-shadow: 0px 1px 2px #000000;
}
/*Responsive - filter reset btn by Dorothy */
@media screen and (max-width: 740px) {
  .flilter-wrap .filter-reset-bt {
    float: left;
    margin-left: 4px;
    padding: 0 12px;
  }
  .flilter-wrap .filter-reset-bt span:last-child {
    display: none;
  }
}