.ant-col > div.poster {
    position: relative;
}
button{
    height:100%;
}
button:focus {
    outline:none !important;
}
.poster {
    max-height: 100%;
    margin: 0 auto;
    padding: 0;
}
.poster .slick-dots {
    bottom: 3em;
    max-width: 1440px;
    left: 50%;
    transform: translateX(-50%);
    text-align: left;
}
.poster .slick-dots li.slick-active button {
    font-weight: 700;
    color: #FFF;
}
.poster .slick-dots li button  {
    font-size: 16px;
    font-weight: lighter;
    color: #F0F0F0;
}
.poster .slick-dots li button::before {
    display: none;
}
.slick-slide img {
  aspect-ratio: 96/ 35;
  height: auto;
}
@media screen and (max-width:480px) {
    .slick-slide img {
        aspect-ratio: 5 / 6;
      }
}