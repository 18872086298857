/*  Board List Header  */
.board-list-header-wrap{
    max-width: 1200px;
    margin:0 auto;
    padding:62px 0px 20px;
}

.flilter-wrap{
    padding-left: 10px;
}

.flilter-write-button{
    height: 100%;
    /* text-align: right; */
    padding:5px 2em 5px 0;
}

.filter-reset-bt {
    height: 40px;
    margin-left: 10px;
}


/*  Board Detail Header  */
.detailHeaderWrap{
    width: 100%;
    margin: 18px auto 0;
    position:relative;
    padding: 24px 0 0;
}

.detail-list-wrap{
    text-align: left;
}

.detail-list-wrap button{
    padding-left: 0;
}

.detailHeader-list-text {
    color: #828282;
    font-weight: 400;
    font-size: 16px;
}

.detail-edit-col {
    text-align: right;
}

.detail-delete-col{
    margin-right:0;
    text-align: right;
}

