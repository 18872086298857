.info {
  padding: 74px;
  padding-right: 24px;
}
.info .txt-main-txt {
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 0em;
}
.introduce-img {
  background: url("./introduce-img.jpg") no-repeat center;
  min-height: 100%;
  background-size: cover;
}
.info .table-subject {
  margin-bottom: 5em;
}
.info .title-wrap {
  margin-bottom: 3em;
}
.para-line {
  width: 3px;
  height: 15px;
  background: var(--point-color, #e94e1b);
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  line-height: 1.5;
  margin-top: -2px;
}
.flag {
  width: 16px;
  height: 5px;
  box-sizing: content-box;
  padding-top: 15px;
  position: relative;
  background: var(--point-color, #e94e1b);
  color: white;
  font-size: 11px;
  letter-spacing: 0.2em;
  text-align: center;
  text-transform: uppercase;
  margin: 0 auto 1em auto;
}
.flag:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-bottom: 6px solid #fff;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}
.table-course .ant-table-row:nth-child(2) td:last-child,
.table-course .ant-table-row:nth-child(3) td:last-child,
.table-course .ant-table-row:nth-child(4) td:last-child,
.table-course .ant-table-row:nth-child(5) td:last-child,
.table-course .ant-table-row:nth-child(8) td:last-child,
.table-course .ant-table-row:nth-child(9) td:last-child {
  display: none;
}
.info .ant-table-tbody > tr > td {
  padding: 1em;
}
.info .txt-para-title {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
}
.info .txt-main-title,
.info .ant-table-thead > tr > th {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}
.info .txt-main-title {
  color: var(--point-color, #e94e1b);
  font-size: 26px;
  margin-bottom: 3em;
}
.info.compose .para-line {
  height: 22px;
}
.info.compose .txt-main-title,
.info.compose .txt-main-txt {
  text-align: left;
}
.info.compose .txt-main-title {
  margin-bottom: 1em;
}
.info .txt-main-txt,
.info .ant-table {
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0em;
  text-align: left;
  color: var(--text-color);
}
.info .txt-sub-txt {
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}
.compose .ant-table-thead,
.compose .ant-table-thead > tr > th {
  border-bottom-color: var(--point-color, #e94e1b);
  background: #f2f2f2;
}

@media only screen and (max-width: 1400px) {
  .info {
    padding: 24px;
  }
  .info {
    margin-top: 3em;
  }
}
@media screen and (max-width: 768px) {
  .info.introduce > .ant-row {
    margin: 0 !important;
  }
  .info.introduce > .ant-row > .ant-col-sm-24 {
    padding: 0 !important;
    min-height: 300px;
    width: 100%;
    margin-bottom: 3em;
  }
}
