.writer-input-title {
  padding-left: 20px;
  margin-top: 5px;
}

.writer-input {
  color: #333333;
  font-size: 16px;
  margin-left: -10px;
  margin-top: -10px;
}
.list-arrow-bt {
  color: #bdbdbd;
  font-size: 20px;
  font-weight: bold;
}

.list-text-bt {
  color: #828282;
  font-weight: 600;
  font-size: 16px;
}

.form-editor-item {
  margin-bottom: 40px;
}

/* 우수작 소개 수정/작성시 들어간 학과선택 select search용 스타일 by Sarc */
#writeForm_department > div {
  max-width: 100%;
  flex: 0 0 100%;
  text-align: left;
}
/* .select-search__value > input {
  height: 30px !important;
} */

.material-select-wrapper {
  width: 100%;
  padding: 20px 0;
}

/* 회원가입 안될 시 문의 작성  by Dorothy */
.detailMainInfoWrap.inquiryAnonymousFormWrap + .ant-row + .ant-row {
  margin: 0 !important;
}

.ant-form-item-with-help .ant-form-item-explain {
  font-size: 13px;
}