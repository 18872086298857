/* Auth Common Css */
.auth-wrap {
  width: 50%;
  height: auto;
  max-width: 600px;
  margin: 10vh auto;
}
.bg-gray {
  background-color: #acacac;
  border-color: #acacac;
}

/* Auth Tab Css  */
.ant-tabs-nav-list {
  width: 100%;
}
.ant-tabs-tab {
  width: 50% !important;
  display: inline-block !important;
  text-align: center;
}

.default-auth-bt {
  width: 100%;
  max-width: 460px;
  height: 56px;
  /* margin-top: 30px; */
  letter-spacing: 0.25em;
}

.sign-wrap {
  margin: 40px auto;
  max-width: 420px;
}
.sign-header {
  margin: 25px auto 35px;
}

.signin-header {
  margin: 20px auto;
}

.sign-header-text {
  font-weight: 600;
  line-height: 3;
}
.form-row {
  text-align: center;
}
.email-text {
  padding-left: 5px;
  text-align: left;
}
.email-text span {
  line-height: 40px;
  margin-left: 10px;
}

.password-input {
  width: 70%;
}

/* 로그인 / 회원가입 탭 텍스트 */
.ant-tabs-tab-btn {
  width: 100%;
  font-family: "Pretendard Bold";
  font-size: 16px;
}

/* select user role */
.select-role-wrap {
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
}
.select-ul {
  width: 100%;
  margin: 0 auto;
  padding: 0;
}
.select-role-bt {
  height: 65px;
  width: 100%;
  margin: 13px auto;
  text-align: left;
  padding-left: 12%;
}
.select-role-bt:hover {
  box-shadow: #000;
}
.select-bt-title {
  font-weight: bold;
}
.login-form-button {
  width: 100%;
}

/* User Check CSS */

/* Add User Info CSS */
.add-user-title {
  margin-bottom: 30px;
  text-align: center;
}
.add-form-row {
  text-align: right;
}

.input-label {
  line-height: 40px;
  padding-right: 20px;
}

.add-form-button {
  margin-top: 50px;
  height: 40px;
  width: 100%;
  max-width: 200px;
}

/**
 * Main wrapper
 */
.select-search {
  width: 100%;
  position: relative;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/* select search */

.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
  * Input
  */
.select-search {
  position: relative;
  z-index: 100;
}
.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 40px 0 16px;
  background: #fff;
  border: 1px solid transparent;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
  * Options wrapper
  */
.select-search__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

/**
  * Options
  */
.select-search__options {
  list-style: none;
  padding: 0;
}

/**
  * Option row
  */
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
  * Option
  */
.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  /* background: #2fcc8b; */
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(168, 168, 168, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  /* background: #2eb378; */
  color: #fff;
}

/**
  * Group
  */
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
  * States
  */
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
  * Modifiers
  */
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #2fcc8b;
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}

.select-search {
  border: 1px solid #d9d9d9;
  box-shadow: none !important;
  color: rgba(0, 0, 0, 0.85);
}
.select-search__input {
  box-shadow: none !important;
}

.select-search .has-focus {
  border: 1px solid #d9d9d9;
}
/* sent email */
.email-text-wrap {
  text-align: center;
}

.email-text-wrap li {
  font-size: 1.2em;
  line-height: 1.8;
}

.email-title-text {
  font-size: 1.5em !important;
  line-height: 2;
  font-weight: 600;
  margin: 25px auto 15px;
}

.email-button-wrap {
  margin: 45px auto 0;
  width: 50%;
  min-width: 350px;
}

.email-button {
  width: 150px;
  height: 50px;
}

/* 회원가입 폼 라벨 정렬 by Sarc */
.ant-form-item-label {
  /*display: inline-flex;*/
  /*justify-content: end;*/
  /*align-items: center;*/
  padding-top: 10px;
}

/* 학생/조교 회원가입 및 마이페이지용 학과선택 폼을 위해 tab의 overflow hidden을 해제. by Sarc */
.ant-tabs.ant-tabs-top {
  overflow: visible;
}
/* 학과 search select의 스타일 맞춤 */
.select-search,
.select-search__input {
  border: none !important;
}
.select-search__value > input {
  padding-left: 0;
}
.user-check-form {
  margin-top: 48px;
}
.sign-wrap label {
  letter-spacing: 1px;
  justify-content: end;
}
.sign-wrap
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}
#login-form .ant-form-item-explain-error,
#user-check-form .ant-form-item-explain-error {
  text-align: right;
  font-size: 0.9em;
}
@media screen and (max-width: 786px) {
  .auth-wrap {
    width: 100%;
    padding: 0 20px;
    max-width: 420px;
    min-width: 350px;
    overflow: scroll;
  }
}
@media screen and (max-width: 380px) {
  .select-role-bt {
    text-align: left;
    padding: 0 1.25em;
  }
  .select-role-bt .select-bt-title {
    font-size: 1em;
  }
  .select-role-bt span {
    font-size: 0.9em;
  }
}



/* terms check */
.terms-check-wrap {
  width: 100%;
  margin: 35px auto 15px;
}

.terms-check-strong {
  color: tomato;
  font-weight: bold;
}
.terms-check-scroll-title{
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 10px 0;
}
.terms-check-scroll-box {
  width: 100%;
  height: 88px;
  padding: 12px 15px;
  overflow: scroll;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  border-top:none;
  text-align: left;
}