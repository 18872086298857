.search-select {
  width: 100%;
  height: 40px;
  text-align: center;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
  /* border-right: none; */
  padding: 4px 11px;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus,
.ant-input:focus,
.ant-input-focused,
.ant-input:hover,
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}
.icon-menu-box {
  /* margin: 0 1rem; */
  display: inline-block;
}
.color-tag-wrap {
  width: 100%;
  margin: 35px 24px 24px;
  /* height: 20px; */
}
.borderLine {
  display: inline-block;
  vertical-align: middle;
  background-color: #ffd560;
  width: 176px;
  height: 2px;
}
.color-tag-info {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
}
/* card */
.ant-card-body {
  padding: 10px;
}
.List .ant-card-body {
  position: absolute;
  bottom: 1em;
  width: 100%;
}
.program-list {
  width: 100%;
  margin: 0 auto;
}
.program-list .ant-col {
  /* padding:20px; */
  height: 100%;
  max-height: 390px;
}
.program-list-card {
  width: 100%;
  min-width: 230px;
  /* max-width: 320px; */
  height: auto;
  min-height: 420px;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #00000020;
  position: relative;
  margin: 0 auto;
  height: 100%;
}
.program-list-card .card-thumbnail-img {
  width: 100%;
  min-width: 230px;
  min-height: 230px;
  position: relative;
}

.program-list-card .ant-card-cover {
  width: 100%;
  min-width: 230px;
  min-height: 230px;
  max-height: 265px;
  overflow: hidden;
  height: auto;
  position: relative;
  top: 0;
  height: 100%;
  background: #fff;
  margin: 0;
}
.program-list-card .ant-skeleton-element,
.program-list-card .ant-skeleton-element .ant-skeleton-image {
  background: #fff;
  height: 100%;
}
.card-stste-tag {
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 15[x];
  text-align: center;
  border-radius: 10px 2px 2px 2px;
}

.card-tag-text {
  color: #ffffff;
  font-size: 10px;
  line-height: 50px;
  text-shadow: 0 0 #333;
}
.program-title {
  text-align: center;
  margin-top: 2.5px;
  /*dorothy - title text 넘침 방지*/
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}
.program-title span {
  font-size: 1.25rem;
  font-weight: 700;
}

.program-info {
  margin: 0 auto;
  width: 92%;
  padding: 10px;
  padding-bottom: 0;
  text-align: center;
}
.program-info ul {
  padding: 0;
}
.program-info li {
  font-size: 0.9rem;
  line-height: 23px;
  text-shadow: 0 0 #333;
}
.program-info li:last-child {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.program-info-title {
  text-align: left;
  display: inline-block;
  width: 25%;
}
.program-info-title li {
  text-shadow: 0 0 #333;
}
.program-info-text {
  text-align: left;
  display: inline-block;
  width: 75%;
}

.program-info-text li {
  color: #333333;
}

.ant-pagination {
  margin-top: 125px;
  position: relative;
  bottom: -10px;
}
.cardImgWrap {
  width: 100%;
  height: 230px;
  margin: 0 auto;
  padding: 0;
}
.skeletonPapa {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
}

.ant-skeleton-element .ant-skeleton-image {
  width: 255px;
  height: 230px;
}
#contbest {
  padding: 0 24px 0 74px;
}
#contbest .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
#contbest .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
#contbest .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  height: 32px;
  line-height: 32px;
  width: 195px;
}
#contbest .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  top: -4px;
}

#contbest .card-write-btn {
  float: right;
}
#contbest .board-top-control-bt {
  margin-top: 40px;
}

#contbest .info-first-col {
  margin: 1em auto;
}
#contbest .info-second-col {
  padding-bottom: 3em;
}
#contbest .programDetailInfoWrap {
  border-color: #f2f2f2;
}
.programDetailInfoWrap,
.qulii-body-row {
  border-color: #ededed;
}

#writeForm .detailMainInfoWrap + .ant-row > .ant-col-24,
#writeForm .detailMainInfoWrap + .ant-row + .ant-row {
  padding: 0 !important;
  margin: 0 !important;
}
@media screen and (max-width: 1336px) {
  .program-list {
    width: 100%;
  }
  #contbest {
    padding: 24px;
  }
}

/* dorothy - Responsive*/
.categoryBtn .icon-menu-box {
  min-width: 65px;
  margin-bottom: 2em;
}
.categoryBtn .programIcon .ant-space-item {
  margin-right: 10px;
}
.categoryBtn .programIcon .ant-space-item:nth-child(2) {
  width: 10px;
}
.List > .ant-space,
.List > .ant-space > .ant-space-item {
  width: 100%;
}
.List > .ant-space > .ant-space-item > .ant-row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.programDetailInfoWrap + div {
  padding: 40px !important;
  justify-content: center;
}
.qulii-body-row {
  max-width: 1200px;
}

@media only screen and (max-width: 1200px) {
  .info-first-col {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 786px) {
  .programIcon {
    width: 100%;
    justify-content: center;
  }
  .color-tag-wrap {
    align-items: center;
    margin-bottom: inherit !important;
  }
  .programIcon .ant-space-item {
    margin-right: 8px !important;
    width: 11%;
  }
  .programIcon .ant-space-item:nth-child(2) {
    width: 20px;
  }
  .categoryBtn .icon-menu-box .iconUl img {
    width: 40px;
    min-width: 40px;
  }
  .categoryBtn .icon-menu-box {
    min-width: 40px;
  }
  .categoryBtn .icon-menu-box .iconUl li {
    font-size: 0.75rem;
  }
  .programDetail > div {
    transform: none;
  }
  .applyModal {
    width: 90% !important;
    top: 10%;
  }
  .applyModal .ant-modal-content {
    padding: 0;
  }
  .applyModal .ant-modal-body {
    padding: 1.5em 0.5em;
  }

  .mainInfo {
    padding: 24px;
  }

  .writer-input-title .ant-form-item-label {
    text-align: right;
  }
  .writer-input-title .writer-input {
    text-align: right;
    width: 100%;
    padding: 0;
    float: right;
  }
}
@media screen and (max-width: 599px) {
  .programDetailInfoWrap .listCol,
  .programDetailInfoWrap .listCol ul {
    padding-right: 0;
    padding-top: 0;
  }
  .programDetailInfoWrap .info-second-col {
    padding-left: 0;
  }
  .ant-form .ant-form-item:last-child {
    margin-bottom: 20px;
}
}
@media only screen and (max-width: 480px) {
  .programIcon .ant-space-item {
    width: 15%;
    margin-right: 4px !important;
  }
  .programIcon .icon-menu-box {
    margin-bottom: 1em;
  }
  .programIcon .ant-space-item:first-child {
    width: 85%;
    padding: 0 !important;
    margin-bottom: 24px;
    border-bottom: 1px solid #22222220;
  }
  .programIcon .ant-space-item:nth-child(2) {
    display: none;
  }
  .categoryBtn .icon-menu-box {
    margin-bottom: 1em;
  }
  .categoryBtn .borderLine {
    width: 120px;
  }
  .categoryBtn .color-tag-wrap {
    margin: 0;
    justify-content: flex-end;
    padding: 15px;
  }
  #cardBox {
    margin-bottom: 1em;
  }
  .categoryBtn .ant-space-item .borderLine {
    display: none;
  }
  .applyModal .applyModalBtn {
    width: 45%;
  }
  .applyModal .ant-typography {
    font-size: 16px;
  }
  .applyModal .ant-form-item:last-child {
    margin: 0 !important;
  }
  .description-wrap {
    flex-direction: column;
  }
  #contbest .board-top-control-bt .write-btn-wrap {
    width: 100%;
    max-width: 100%;
    flex: 100%;
    text-align: right;
    margin-top: 1em;
  }
  .programDetailInfoWrap + div {
    padding: 20px !important;
    margin: 20px;
  }
  .programDetailInfoWrap .info-first-col {
    margin-top: 0;
  }
  .qulii-body-row {
    margin: 0  20px;
    padding: 0!important;
  }
  .qulii-body-row .ql-editor {
    padding: 0;
  }
}
/* 작성자 안보이게 */
.writer-input-title {
  visibility: hidden;
  margin: 0;
}
.writeForm > .detailMainInfoWrap {
 max-height: 90px;
}