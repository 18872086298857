.Main {
  margin: 0 auto;
  max-width: 100vw;
  overflow: hidden;
  width: 100%;
}

.iconList {
  /* padding:70px 0; */
  margin: 0 auto;
}

.iconList .ant-col {
  width: 100%;
  height: 100%;
  margin: 0 12px;
}
.iconList .ant-col a {
  width: 100%;
  display: inline-block;
  overflow: hidden;
  max-width: 120px;
}

.iconUl {
  margin: 0;
  padding: 0;
}
.iconUl img {
  max-width: 100%;
}
.iconUl li {
  color: #4f4f4f;
  font-size: 1rem;
}

.iconUl li:last-child {
  margin-top: 20px;
}

.ant-tabs {
  width: 100%;
  margin: 0 auto;
}
.card-container {
  width: 100%;
}
.card-container .card-wrap a::after {
  content: '상세보기';
  font-size: 0;
}
.card-container .card-description div > * {
  margin: 0;
  display: inline;
  padding-right: 4px;
  font-size: 1em;
  font-weight: normal;
}
.card-container .card-description a {
  margin: 0;
  display: inline;
  padding-right: 4px;
  font-size: 1em;
  font-weight: normal;
}
.card-container img {
  display: none;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000000;
  font-weight: 400;
}
.card-container > .ant-tabs-card .ant-tabs-content {
  height: auto;
  margin-top: -17px;
  border: 1px solid #f2f2f2;
  position: relative;
}
.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 16px;
  background: #fff;
}

.card-wrap {
  position: relative;
  padding: 10px 20px;
  margin: 0;
  text-align: justify;
}
.card-tag {
  text-align: left;
  margin-bottom: 10px;
}
.ant-tag {
  border-radius: 15px !important;
}
.card-title {
  text-align: left;
  font-weight: 600;
  font-size: 1.2rem;
  height: 64px;
  overflow: hidden;
}
.card-text {
  text-align: left;
  margin-top: 20px;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 0.95rem;
  color: #525252;
}
.small-text {
  text-align: right;
}
.small-text a,
.small-text span {
  font-size: 0.7rem;
  line-height: 0.9rem;
  color: #525252 !important;
}

.iconListTitle {
  text-align: left;
  max-width: 200px;
  padding: 0;
}

.listText {
  height: 1.85rem;
  margin: 0;
  padding-top: 10px;
  font-size: 1.65rem;
  font-weight: 100;
  vertical-align: top;
  letter-spacing: -1px;
}

.textBold {
  height: 1.85rem;
  margin: 0;
  padding-top: 10px;
  font-size: 1.65rem;
  vertical-align: top;
  font-weight: bold;
}

.listMore {
  height: 1rem;
  width: 80%;
  margin: 25px 0;
  font-size: 0.9rem;
  /* font-weight: 100; */
  vertical-align: top;
  text-align: right;
  color: #828282;
}

@media (max-width: 1440px) {
}

@media (max-width: 1024px) {
  .listText,
  .textBold {
    font-size: 1.45rem;
  }
  .iconList {
    padding: 70px 0;
    max-width: 80%;
    margin: 0 auto;
  }
  .listMore {
    font-size: 0.9rem;
    font-weight: 100;
    vertical-align: top;
    text-align: right;
    color: #828282;
  }
}
body {
  width: 100vw;
}
.programSlide {
  height: 100%;
  max-height: 400px;
  min-width: 300px;
}
.programSlide > div {
  max-height: 400px;
}
.main .ant-layout-content,
.main .ant-row {
  position: relative;
}
.gallerySlide .slick-dots {
  display: none !important;
}
.ant-tabs-tab-btn:active,
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:active,
.ant-tabs-tab-remove:focus {
  color: #222;
}
.gallerySlide .ant-card-body {
  min-height: 122px;
}
.gallerySlide .ant-card-body .gallery-card-title span {
  font-size: 1.25rem;
  font-weight: 400;
  color: #5a5a5a;
  display: inline-block;
  width: 102%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0.5em;
}
.gallerySlide .slick-slide .ant-card img {
  height: 250px;
}
.site-title-wrapper,
.site-card-wrapper,
.board-card-wrapper {
  padding: 160px 1em;
  position: relative;
  /*overflow: hidden;*/
}
.site-title-wrapper {
  background: #ecd7be;
}
.site-title-wrapper::before {
  content: "";
  display: block;
  position: absolute;
  width: 50vw;
  height: 100%;
  background: #ecd7be;
  left: 0;
  top: 0;
  transform: translateX(-99.9%);
}
.board-list-more {
  font-size: 0;
}
.board-list-more img {
  display: inline-block;
  background: #f0b670;
  width: 68px;
  height: 68px;
}
.boardTabMain {
  text-align: left;
}
.card-description {
  overflow: hidden;
  height: 6em;
  margin: 8px auto;
}
.card-pin {
  position: absolute;
  top: -10px;
  transform: translateY(-100%);
  font-size:1.2em;
}
.card-pin path:first-child {
  fill:#FFF;
}
.AdventureService {
  text-align: left;
  padding-right: 5em;
}

.board-card-wrapper .ant-col:nth-child(2) .ant-card {
  background-image: url("./AdventureService-2.webp");
}
.board-card-wrapper .ant-col:nth-child(3) .ant-card {
  background-image: url("./AdventureService-3.webp");
  margin: 0;
}
.board-card-wrapper .ant-col.ant-col-12 .ant-card {
  background-image: url("./AdventureService-1.webp");
  line-height: 1.5;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* IE and Edge style */
  .board-card-wrapper .ant-col:nth-child(2) .ant-card {
    background-image: url("./AdventureService-2.jpg");
  }
  .board-card-wrapper .ant-col:nth-child(3) .ant-card {
    background-image: url("./AdventureService-3.jpg");
  }
  .board-card-wrapper .ant-col.ant-col-12 .ant-card {
    background-image: url("./AdventureService-1.jpg");
  }  
}
@supports (-webkit-text-size-adjust:none) and (not (-ms-accelerator:true))
and (not (-moz-appearance:none))
{
  /* Safari 9.0+ (iOS Only) */
  .board-card-wrapper .ant-col:nth-child(2) .ant-card {
    background-image: url("./AdventureService-2.jpg");
  }
  .board-card-wrapper .ant-col:nth-child(3) .ant-card {
    background-image: url("./AdventureService-3.jpg");
  }
  .board-card-wrapper .ant-col.ant-col-12 .ant-card {
    background-image: url("./AdventureService-1.jpg");
  }  
}
.board-card-wrapper .ant-card {
  height: 100%;
  min-height: 500px;
  background-position: center;
  margin-right: 1em;
}
.Main .ant-card-body {
  padding: 0;
}
.Main .ant-card-cover img {
  height: 100%;
  width: auto;
}
.Main .ant-card-bordered .ant-card-cover {
  overflow: hidden;
}
.Main .ant-layout {
  background: transparent;
}
.Main .slick-slide > div {
  line-height: 0;
}
.card-container .txt-main-title {
    margin-top: 1.2em;
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 1em;
}
/* Responsive */
.onlyMobile {
  display: none;
}
@media (max-width: 1280px) {
  .sbcBoard::before {
    height: 60%;
  }
  .programSlide .slick-slider .slick-list,
  .programSlide .slick-slider .slick-track {
    height: 300px;
    overflow: hidden;
  }
  .boardTabMain .card-wrap {
    padding: 0;
  }
  .board-card-wrapper {
    padding-right: 1em;
    padding-left: 1em;
  }
}
@media (max-width: 990px) {
  .boardTabMain .card-title {
    height: 1.6em;
  }
  .boardTabMain .ant-card-body,
  .boardTabMain .small-text {
    margin: 0;
    padding: 0;
  }
  .programSlide {
    margin-top: 40px;
  }
  .banerMain > .ant-col a {
    text-align: center !important;
  }
  .sbcBoard::before {
    height: 70%;
  }
  .programMain .onlyPc {
    display: none;
  }
  .AdventureService {
    padding-right: 2em;
  }
}
@media (max-width: 786px) {
  .onlyPc {
    display: none;
  }
  .onlyMobile {
    display: block;
  }
  .sm-view-padding {
    /*padding: 15px; */
    box-sizing: border-box;
  }
  .iconList .ant-col {
    margin: 0 4px;
  }
.site-title-wrapper,
.site-card-wrapper,
.board-card-wrapper {
  padding: 50px 20px;
  width:100%;
  text-align: left;
}
  .boardTabMain {
    min-width: 94vw;
  }
  .gallerySlide .ant-card-body .gallery-card-title span {
    white-space: normal;
    line-height: 1.2;
    height: 2.3em;
    word-wrap: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .programSlide {
    min-width: 98% !important;
  }
  .programSlide .slick-track .slick-slide > div > div {
    width: 95% !important;
  }
  .programSlide .slick-slider .slick-list,
  .programSlide .slick-slider .slick-track {
    height: min-content;
    padding-bottom: 1em;
  }
  .site-title-wrapper::before {
    min-width: 500px;
  }
  .board-card-wrapper .ant-card {
    min-height: 300px;
  }
  
  .AdventureService {
    padding-right: 0;
  }
  .site-title-wrapper::before {
    background:transparent;
  }
  .site-card-wrapper > .ant-row { margin: 0!important;}
  .site-card-wrapper > .ant-row > .ant-col {padding: 0!important;}
}
@media (max-width: 480px) {
  .listMore {
    max-width: 85px;
  }
  .boardTabMain {
    min-width: 100%;
    padding: 0;
  }
  .boardTabMain .site-card-wrapper .card-wrap {
    padding: 0;
  }
  .card-title {
    height: 3em;
  }
  .gallerySlide .iconList {
    padding: 0;
  }
  .sbcBoard::before {
    height: 90%;
    bottom: 4%;
  }
  .gallerySlide .slick-slide .ant-card {
    margin-left: 0 !important;
  }
  /* .PosterSlide 5.13 dorothy */
  .programSlide .slick-slider .slick-list,
  .programSlide .slick-slider .slick-track {
    height: 300px;
  }
  .gallerySlide .slick-slide {
    padding-left: 4px;
  }
  .gallerySlide .ant-card-body .gallery-card-title span {
    font-size: 1em;
  }
  .gallerySlide .ant-card-body {
    height: 80px;
    min-height: auto;
  }
  .gallerySlide .slick-slide .ant-card img {
    height: 160px;
  }
  .gallerySlide .slick-list,
  .gallerySlide .slick-slider {
    width: 180%;
  }
  .boardTabMain .ant-tabs-nav-operations {
    display: none;
  }
  .board-card-wrapper .ant-card {
    height: 300px;
  }
  .AdventureService {
    padding-right: 0;
  }
}
@media (max-width: 350px) {
  .ant-layout,
  .header-navigation,
  footer,
  body {
    min-width: 350px;
  }
  body {
    overflow: scroll;
    padding-top: 0;
  }
  .board-main-wrap {
    padding-top: 60px;
  }
  .sm-view-padding {
    padding: 0;
  }
  .Main {
    max-width: 100%;
    min-width: 350px;
  }
  .header-navigation {
    position: absolute;
  }
  .ant-drawer .ant-col .ant-col {
    max-width: 100%;
    flex: 100%
  }
  .ant-drawer-body .submenuDiv a {
    line-height: 40px;
    margin-bottom: 2.5em;
  }
}