.loading-wrap{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1000;
    overflow: hidden;
    background-color: transparent;
    /* background-color: rgba(241, 241, 241, 0.5) */
}

.spinner-box{
    position: fixed !important;
    top: 50%;
    right: 50%;
    left: 0;
    bottom: 0;
    transform: translate(50%, 0);
    z-index: 1001;
}

.ant-spin-text{
    margin-top:5px;
}


/* loading-flex */
.loading-flex-wrap {
    height: 100%;
    width: 100%;
    position: relative;
}

.spinner-flex-box{
    position: absolute !important;
    top: 50%;
    right: 50%;
    left: 0;
    bottom: 0;
    transform: translate(50%, 0);
    z-index: 1001;
}

/* error view */
.error-wrap{
    width: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    transform: translateY(50%);
}
.error-wrap .ant-result-error{
    width: 100%;
}

/* .ant-result-icon{}
.ant-result-title{}
.ant-result-extra{} */

/*  NO ACCESS */

#access {
    background: #C4C4C405;
    height: calc( 100vh - 120px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5vh 0;
}
#access .contents-wrap {
    padding: 3em 1em;
    border-radius: 30px;
    background: #FFFFFF;
    box-shadow: 0px 2px 16px 0px #00000026;
    display: flex;
    flex-direction: column;
    gap: 44px;
    width: 70%;
    max-width: 520px;
    margin: 0 1em;
}
#access .contents-wrap img {
    width: 100%;
    max-width: 200px;
}
#access .txt-wrap .txt-para-title {
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 24px;
}
#access .txt-wrap .txt-main-txt {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0em;
    text-align: center;

}
.txt-main-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
}
#access .contents-wrap button {
    word-spacing: 1em;
    text-align: center;
    height: 56px;
    max-width: 280px;
    width: 100%;
    background: var(--point-color);
    border-color: var(--point-color);
}
@media screen and (max-width: 1046px) {
    #access {
        height: calc(100vh - 60px)
    }
}
@media screen and (max-width: 418px) {
    #access {
        background: #FFF;
    }
    #access .contents-wrap {
        box-shadow: none;
        width: 100%;
    }
}