.qna-detail {
    padding: 0 50px 50px 50px;
}

.comment-wrapper{
    width: 100%;
}

.ant-comment-content-detail{
    background-color:#ffffff !important;
}

.comment-box{
    margin-top:10px;
    border: 1px solid #e0e0e0;
    padding: 10px 15px;
}

.st-empty-reply-box {
    width: 100%;
    height: 150px;
    margin-top:10px;
    border: 1px solid #e0e0e0;
    padding: 10px 15px;
    text-align: center;
}

.st-empty-reply-box span {
    font-size: 14px;
    font-weight:600;
    color: #bdbdbd;
    line-height: 130px;
}